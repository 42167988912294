<template>
  <div>
    <v-container v-if="showTest">
      <h2 class="mt-10 mb-7">{{ $t("attestation.test") }}</h2>
      <v-row>
        <v-col
          cols="12"
          :sm="question.image ? 12 : 6"
          v-for="(question, questionId) in testQuestionsList"
          :key="questionId"
        >
          <h4 class="text-sm-left">
            {{ questionId + 1 }}. {{ $t(question.question) }}
          </h4>
          <img
            :src="require(`../assets/testImages/${question.image}`)"
            v-if="question.image"
            style="max-width: 100%"
            class="d-flex align-sm-start"
          />
          <v-radio-group
            v-model="testAnswers[questionId]"
            :readonly="isFinished"
          >
            <v-radio
              v-for="(answer, answerId) in question.answerOptions"
              :key="answerId"
              :label="answer.text"
              :value="answer.id"
              style="justify-content: space-between"
            >
              <template slot="label">
                <span class="flex">
                  <span>
                    <!-- For testing porpuse :class="`${
                      answerId === question.correctAnswerId
                        ? 'greenText'
                        : 'redText'
                    }`" -->
                    {{ $t(answer.text) }}
                  </span>
                </span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-col
        cols="12"
        justify="center"
        align="center"
        class="px-10"
        v-if="isFinished"
      >
        <v-row align="center" justify="center" class="mt-5 text-center">
          <h3 :class="`${isTestPassed ? 'greenText' : 'redText'}`">
            {{
              isTestPassed
                ? $t("attestation.test-passed")
                : $t("attestation.test-not-passed")
            }}
            ({{ correctAnswersCount }} / {{ testAnswers.length }})
          </h3>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        class="px-10"
        v-if="!isTestPassed && allowAnotherAttempt"
      >
        <v-btn
          target="_blank"
          block
          color="accent"
          @click="!isFinished ? finishTest() : restartTest()"
          :disabled="isBtnDisabled()"
        >
          {{
            isFinished && allowAnotherAttempt
              ? $t("attestation.try-again")
              : $t("attestation.apply-test")
          }}</v-btn
        >
      </v-col>
    </v-container>
    <v-container v-if="!showTest">
      <h1 class="mt-10">
        {{
          attestationData.isActive != null &&
          attestationData[`${localTestName}`] != null &&
          attestationData[`${localTestName}`].isActive == false
            ? $t("attestation.test-completed")
            : ""
        }}
      </h1>
    </v-container>
  </div>
</template>

<script>
import { db } from "@/main";
import { attestationTestsNames } from "@/helpers/constants";
import { filter, forEach, includes, isEmpty, isEqual, isNil } from "lodash";
import { dateToIsoString } from "@/helpers/dateTimeConvertingFunctions";
export default {
  props: ["testData", "testName"],
  data() {
    return {
      testQuestionsList: [],
      isLoading: true,
      testAnswers: [],
      isFinished: false,
      isTestPassed: false,
      correctAnswersCount: 0,
      currentUserId: this.$root.currentUserData.userId,
      attestationData: {},
      showTest: false,
      allowAnotherAttempt: true,
      localTestName: this.testName,
      attestationsTestsNames: attestationTestsNames,
    };
  },
  async created() {
    await this.getAttestation();
    this.getTutorialData();
  },
  methods: {
    async getAttestation() {
      const attestationResponse = await db
        .collection("attestations")
        .where("userId", "==", this.currentUserId)
        .where("isActive", "==", true)
        .get();
      if (!isEmpty(attestationResponse.docs)) {
        let data = attestationResponse.docs[0].data();
        data.id = attestationResponse.docs[0].id;
        this.attestationData = data;
        //check if test is not finished
        if (
          isNil(data[`${this.testName}`]) ||
          (data[`${this.testName}`] && data[`${this.testName}`].isActive)
        ) {
          this.showTest = true;
        }
      }
    },
    isAnswerSelected(questionId, answerId) {
      return isEqual(this.testAnswers[questionId], answerId);
    },
    isBtnDisabled() {
      let count = 0;
      forEach(this.testAnswers, (answer) => {
        if (!isNil(answer)) {
          count++;
        }
      });

      return !isEqual(this.testQuestionsList.length, count);
    },
    async finishTest() {
      this.isFinished = true;
      const correctAnswersCount = this.countCorrectAnswers();
      if (correctAnswersCount >= this.testQuestionsList.length - 2) {
        this.isTestPassed = true;
      } else {
        this.isTestPassed = false;
      }
      this.correctAnswersCount = correctAnswersCount;
      await this.saveTestToDb();
    },
    restartTest() {
      this.isFinished = false;
      this.isTestPassed = false;
      this.testAnswers = [];
    },
    async saveTestToDb() {
      const attestationObj = this.attestationData;
      let testAnswers = [];
      let now = new Date();
      forEach(this.testQuestionsList, (question, index) => {
        testAnswers.push({ [index]: this.testAnswers[index] });
      });
      let userTestAttempts = [];
      if (
        attestationObj[`${this.testName}`] &&
        attestationObj[`${this.testName}`].testAttempts
      ) {
        userTestAttempts = attestationObj[`${this.testName}`].testAttempts;
        this.allowAnotherAttempt = false;
      } else {
        this.allowAnotherAttempt = true;
      }
      let attemptObj = {
        correctAnswers: this.correctAnswersCount,
        date: now,
        dateFormatted: dateToIsoString(now),
        answers: testAnswers,
      };
      userTestAttempts.push(attemptObj);

      let userRankPoints = attestationObj.rankPoints;
      let testUpdateObject = {};
      if (this.isTestPassed) {
        testUpdateObject = {
          isActive: false,
          isSucessful: true,
          testAttempts: userTestAttempts,
        };
        userRankPoints += 1;
      } else {
        testUpdateObject = {
          isActive: isEqual(userTestAttempts.length, 1) ? true : false,
          isSucessful: false,
          testAttempts: userTestAttempts,
        };
      }
      let attestationUpdateObj = {};
      if (this.checkIfAllAttestationsTestsCompleted()) {
        if (this.isTestPassed || userTestAttempts.length > 1) {
          attestationUpdateObj = {
            [`${this.testName}`]: testUpdateObject,
            rankPoints: userRankPoints,
            isActive: false,
            finishedDate: now,
            finishedDateFormatted: dateToIsoString(now),
          };
        } else {
          attestationUpdateObj = {
            [`${this.testName}`]: testUpdateObject,
            rankPoints: userRankPoints,
            isActive: true,
          };
        }
      } else {
        attestationUpdateObj = {
          [`${this.testName}`]: testUpdateObject,
          rankPoints: userRankPoints,
          isActive: true,
        };
      }
      await db
        .collection("attestations")
        .doc(attestationObj.id)
        .update(attestationUpdateObj);
      await this.getAttestation();
    },
    checkIfAllAttestationsTestsCompleted() {
      let vm = this;
      let completedTestsCount = 0;
      const names = vm.attestationsTestsNames;
      const excludeBfhTest = !includes(
        vm.attestationData.userAiderServices,
        "bfh"
      );
      // -1 is for current test, another -1 is for users without bfh service
      const totalCount = excludeBfhTest ? names.length - 2 : names.length - 1;
      forEach(names, (foundTestName) => {
        if (
          !isEqual(foundTestName, vm.testName) &&
          !isNil(vm.attestationData[`${foundTestName}`]) &&
          !vm.attestationData[`${foundTestName}`].isActive
        ) {
          completedTestsCount++;
        }
      });
      return isEqual(completedTestsCount, totalCount);
    },
    countCorrectAnswers() {
      let correctAnswers = 0;
      forEach(this.testQuestionsList, (question, index) => {
        if (isEqual(question.correctAnswerId, this.testAnswers[index])) {
          correctAnswers++;
        }
      });
      return correctAnswers;
    },
    getTutorialData() {
      const isBfhUser = includes(this.attestationData.userAiderServices, "bfh");
      const filteredQuestions = filter(
        this.testData,
        (test) => isNil(test.isBfh) || !test.isBfh || (isBfhUser && test.isBfh)
      );
      this.testQuestionsList = filteredQuestions;
    },
    shuffleTestAnswers() {
      forEach(this.testQuestionsList, (testItem) => {
        this.shuffleList(testItem.answerOptions);
      });
    },
    shuffleList(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
  },
};
</script>


<style scoped>
.v-btn__content {
  white-space: normal;
  flex: auto;
}

.v-btn {
  min-height: 52px;
  height: 100% !important;
}

.redText {
  color: red;
}
.greenText {
  color: green;
}
</style>